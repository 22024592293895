






















































import {Component, Vue} from 'vue-property-decorator';
import ViewDesign from "@/components/global/ViewDesign.vue";

@Component({  components: {ViewDesign}})
export default class AcceptCookies extends Vue {

    private acceptCookies(lang: string): void {

        this.$store.commit('SET_ACCEPTCOOKIES', true);
        this.$router.push('/' + lang);
    }

}

